import { useState } from 'react';
import { BsFileCheck, BsFiles } from 'react-icons/bs';

const Faq = () => {
  const [copied, setCopied] = useState([false, false, false]);
  const USDTAddress = [
    ['ERC20', '0x8F47E3Cc0c09d114f7103125F4161f5E38cA3D67'],
    ['TRC20', 'TDUEVi2Kg3HnPDbnaxnHnxKtMimtJgGvsV'],
    ['Solana', 'ChhfaujfZ2w8YFTcsU4BBtVWdmpUG4PcbsqndxNoy83Q'],
  ];
  const handleCopy = ({ index = null }) => {
    navigator.clipboard.writeText(USDTAddress[index][1]);
    const temp = [].concat(copied);
    temp[index] = true;
    setCopied(temp);
  };

  return (
    <div className="faq-timeline-area section-padding-100-85" id="faq">
      <div className="container">
        <div className="section-heading text-center">
          {/* <!-- Dream Dots --> */}
          <div
            className="dream-dots justify-content-center fadeInUp"
            data-wow-delay="0.2s"
          >
            <span>FAQ</span>
          </div>
          <h2 className="fadeInUp" data-wow-delay="0.3s">
            常見問題
          </h2>
          {/* <p className="fadeInUp" data-wow-delay="0.4s">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis
              accumsan nisi Ut ut felis congue nisl hendrerit commodo.
            </p> */}
        </div>
        <div className="row align-items-center">
          {/* <div className="col-12 col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-sm-12">
              <img
                src="img/core-img/0faq.png"
                alt=""
                className="center-block img-responsive"
              />
            </div> */}
          <div className="col-12 col-lg-6 col-md-12 m-auto">
            <div className="dream-faq-area mt-s ">
              <dl style={{ marginBottom: '0' }}>
                {/* <!-- Single FAQ Area --> */}
                <dt className="wave fadeInUp" data-wow-delay="0.2s">
                  如何購買 USDT?
                </dt>
                <dd className="fadeInUp" data-wow-delay="0.3s">
                  <p>
                    <a
                      href="/article/maicoin"
                      target="_blank"
                      rel="noreferrer"
                    >
                      MaiCoin 開戶&出金教學
                    </a>
                    <br />
                    <a
                      href="/article/binance"
                      target="_blank"
                      rel="noreferrer"
                    >
                      幣安開戶教學
                    </a>
                    <br />
                    <a
                      href="/article/binanceWithdrawal"
                      target="_blank"
                      rel="noreferrer"
                    >
                      幣安出金教學
                    </a>
                  </p>
                </dd>
                {/* <!-- Single FAQ Area --> */}
                <dt className="wave fadeInUp" data-wow-delay="0.3s">
                  如何入金?
                </dt>
                <dd>
                  <div>
                    <ul>
                      <li>
                        <p>
                          準備好USDT穩定幣（如果沒有推薦透過{' '}
                          <a
                            href="/article/maicoin"
                            target="_blank"
                            rel="noreferrer"
                          >
                            MaiCoin
                          </a>{' '}
                          和{' '}
                          <a
                            href="/article/binance"
                            target="_blank"
                            rel="noreferrer"
                          >
                            幣安
                          </a>
                          購買）
                        </p>
                      </li>
                      <li>
                        <p>
                          將 USDT 轉入指定地址
                          <br />
                          建議使用者先打入小筆USDT，確認無誤後再大筆匯入。幣安和MaiCoin帳戶推薦使用TRC20地址打入，可省最多手續費。以幣安來說ERC20手續費約24美金，TRC20只需要1美金。
                          <br />
                          {/* {USDTAddress.map((item, index) => (
                            <>
                              <span className="size10">
                                <br />
                                {item[0]}
                                <br />
                                {item[1]}{' '}
                              </span>
                              {copied[index] ? (
                                <BsFileCheck />
                              ) : (
                                <BsFiles
                                  onClick={() =>
                                    handleCopy({ index })
                                  }
                                />
                              )}
                            </>
                          ))} */}
                          {/* <span className="size10">
                              <br />
                              ERC20
                              <br />
                              0x8F47E3Cc0c09d114f7103125F4161f5E38cA3D67{' '}
                            </span>
                            <BsFiles
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  '0x8F47E3Cc0c09d114f7103125F4161f5E38cA3D67',
                                )
                              }
                            />
                            <BsFileCheck />
                            <span className="size10">
                              <br />
                              <br />
                              TRC20
                              <br />
                              TDUEVi2Kg3HnPDbnaxnHnxKtMimtJgGvsV
                            </span>
                            <br />
                            <br />{' '}
                            <span className="size10">
                              Solana
                              <br />
                              ChhfaujfZ2w8YFTcsU4BBtVWdmpUG4PcbsqndxNoy83Q
                            </span>
                            <br />
                            <br /> */}
                          {/* </span> */}
                        </p>
                      </li>
                      <li>
                        <p>
                          將自己的地址和截圖發給您的推薦人，推薦人確認收款後會為你建立一個帳號連結，未來每日投資者就可以透過該連結確認自己的投資收益。
                        </p>
                      </li>
                    </ul>{' '}
                    {/* <a
                      href="/withdrawalTeaching.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      入金教學
                    </a> */}
                  </div>
                </dd>
                <dt className="wave fadeInUp" data-wow-delay="0.3s">
                  如何出金?
                </dt>
                <dd>
                  <div>
                    <p>
                      點擊下方窗口提出出金需求，404fund將會把USDT穩定幣退回給您，匯費依照您的地址鏈條不同來扣款（注意！如果入金未超過半年，將只能取回本金，收益都會歸回404Fund所有））。
                    </p>
                    <button class="btn dream-btn" onClick={() => window.open('https://t.me/foffund')}>出金窗口</button>
                  </div>
                </dd>
                {/* <!-- Single FAQ Area --> */}
                <dt className="wave fadeInUp" data-wow-delay="0.4s">
                  404 fund 如何運作?
                </dt>
                <dd>
                  <p>
                    我們的程式在尋找眾多加密貨幣交易所中，有著「現貨與期貨價差+交易合約資金費率-手續費>0」的套利機會，並透過我們創造的交易機器人快速進場獲利。
                  </p>
                  <p>
                    Ex. <br />
                    交易幣對: SRM_USDT <br />
                    走期：2021/9/17 23:11 - 2021/9/18 00:00
                    <br />
                    現貨買進價格: 9.693 USDT
                    <br />
                    合約做空價格: 9.699 USDT
                    <br />
                    現貨賣出價格: 9.75 USDT
                    <br />
                    合約平倉價格: 9.734 USDT
                    <br />
                    資金費率: 0.0197(2021/9/18 00:00:00 該期資金費率) *
                    9.699(2021/9/18 00:00:00 幣價)=0.1910703 <br />
                    總手續費: ((9.693 + 9.75) * (0.04425%(現貨手續費))) +
                    ((9.699 + 9.734) * (0.02124%(合約手續費))) = 0.0127310967
                    <br />
                    價差: -現貨買進價格+合約做空價格+現貨賣出價格-合約平倉價格
                    => (-9.693) + 9.699 + 9.75 - 9.734 = 0.022 <br />
                    總獲利 = 價差 + 資金費率 - 手續費 => 0.022+ 0.1910703-
                    0.0127310967= 0.200339203
                    <br />
                    以上行為你無需盯盤、無需一直找最好的機會、節省最多的時間，賺得最穩定的套利。
                  </p>
                </dd>
                {/* <!-- Single FAQ Area --> */}
                <dt className="wave fadeInUp" data-wow-delay="0.5s">
                  404 Fund 的年化報酬率為多少？
                </dt>
                <dd>
                  <p>
                    404
                    Fund的年化報酬率約為9%-45%，當加密貨幣市場變動越大，不論上漲或下跌，404Fund
                    都能獲得好的回報，但如果加密貨幣市場平淡如水，404Fund
                    的回報也會較為平穩。
                  </p>
                  <p>
                    實例： 科技網站創辦人於2021年5月6日投入資金40,000USDT
                    <br />
                    本金：40,000USDT <br />
                    累積時間：127日 <br />
                    累積獲利：5063.675 <br />
                    時間內報酬率：12.65% <br />
                    推算年化報酬率：36.24%
                  </p>
                </dd>
                <dt className="wave fadeInUp" data-wow-delay="0.5s">
                  我能即時知道放入404 Fund的報酬嗎？
                </dt>
                <dd>
                  <p>
                    當然可以，我們會提供一個專屬通道，透過這個通道你可以即時知道目前的報酬獲利。
                    <br />
                    報表範例（和連結）
                  </p>
                </dd>
                <dt className="wave fadeInUp" data-wow-delay="0.5s">
                  為什404 Fund 可以做到？
                </dt>
                <dd>
                  <div>
                    <ul>
                      <li>
                        <p>
                          手動操作是無法快速找到機會以及出場獲利找更好的機會，唯有程式可以做到
                        </p>
                      </li>
                      <li>
                        <p>
                          現有的交易所中，雖然有提供交易機器人，但投資人必須先選定單一加密貨幣合約才能執行交易機器人，靈活度比404
                          Fund 小上很多。
                        </p>
                      </li>
                      <li>
                        <p>
                          我們不僅跨交易所找到更好的套利機會，單一資金進出的手續費成本比個人操作小更多，增加更多套利機會。
                        </p>
                      </li>
                      <li>
                        <p>
                          複利投入：每次套利獲得的費用將快速地投入下一次的套利機會當中。
                        </p>
                      </li>
                    </ul>
                  </div>
                </dd>
                <dt className="wave fadeInUp" data-wow-delay="0.5s">
                  為什麼不直接購買加密貨幣投資或流動性挖礦等獲利產品？
                </dt>
                <dd>
                  <p>
                    加密貨幣市場起伏巨大，流動性挖礦在質押加密貨幣的時候，仍要承受該貨幣下跌的風險。透過期限價差鎖定價格的方式，將可避免加密貨幣價格上下波動的風險。
                  </p>
                </dd>
                <dt className="wave fadeInUp" data-wow-delay="0.5s">
                  世上沒有穩賺不賠的金融產品，404 Fund 的風險在哪？
                </dt>
                <dd>
                  <div>
                    <ul>
                      <li>
                        <p>
                          當加密貨幣市場一直處於非常平穩狀態時，我們的交易系統可能找不到套利機會而失去資金的時間成本。
                        </p>
                      </li>
                      <li>
                        <p>
                          當交易系統為了取得最大的套利機會時，鋌而走險購買流動性較差的加密貨幣合約，導致套利失敗。這點404
                          Fund 的系統將會以合約流動性最佳的方式來選擇交易。
                        </p>
                      </li>
                    </ul>
                  </div>
                </dd>
                <dt className="wave fadeInUp" data-wow-delay="0.5s">
                  天下沒有白吃的午餐，參與404 Fund 的條件和成本為何？
                </dt>
                <dd>
                  <div>
                    <ul>
                      <li>
                        <p>
                          參加條件：最低加入單位為10,000 USDT
                          ，資金最少放入時間為半年。
                        </p>
                      </li>
                      <li>
                        <p>
                          服務費用：我們依照年化報酬率和入金金額有不同的服務費用，當年化報酬率6%以下時，我們將不收取任何費用。詳情請見我們的詳細說明文件（連結）。
                        </p>
                      </li>
                      <li>
                        <p>
                          出金手續費：出金手續費相當便宜，每次約0.82美元（TODO）。
                        </p>
                      </li>
                    </ul>
                  </div>
                </dd>
                <dt className="wave fadeInUp" data-wow-delay="0.5s">
                  我媽媽昨天說想買房子，我可以即時贖回資金嗎？
                </dt>
                <dd>
                  <p>
                    投入第一筆資金最低存放時間為半年，半年後可隨時贖回。如果半年內要贖回資金，半年內所得獲利將不能贖回，本金則可贖回。
                  </p>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Faq;
