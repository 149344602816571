import { useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Element } from 'react-scroll';
import styles from './styles.module.css';

const OurTeam = ({ data = null, title, des, subtitle, name }) => {
  const [more, setMore] = useState([]);
  return (
    <Element name={name}>
      <section
        className="our_team_area section-padding-100-0 clearfix"
        id={name}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading text-center">
                {/* <!-- Dream Dots --> */}
                <div
                  className="dream-dots justify-content-center fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <span>{title}</span>
                </div>
                {subtitle && (
                  <h2 className="fadeInUp" data-wow-delay="0.3s">
                    {subtitle}
                  </h2>
                )}
                {des && (
                  <p className="fadeInUp" data-wow-delay="0.4s">
                    {des}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            {/* <!-- Single Team Member --> */}
            {data.map((i, v) => {
              if (!more[v]) more[v] = false;
              return (
                <div className="col-12 col-sm-6 col-lg-3">
                  <div
                    className={`single-team-member fadeInUp ${styles.teamMember}`}
                    data-wow-delay="0.2s"
                  >
                    {/* <!-- Image --> */}
                    {/* <a
                href="https://opensea.io/atticuswu"
                target="_blank"
                rel="noreferrer"
              > */}
                    <div
                      className={`team-member-thumb ${styles.pic}`}
                      style={{
                        backgroundImage: `url("${
                          i.pic || 'img/team-img/2.png'
                        }")`,
                      }}
                    ></div>
                    {/* </a> */}
                    {/* <!-- Team Info --> */}
                    <div className="team-info">
                      <h5 className="w-text">{i.name}</h5>
                      {i.title && <p className="g-text">{i.title}</p>}
                      {i.des && (
                        <>
                          {!more[v] && (
                            <BsChevronDown
                              className={styles.more}
                              onClick={() => {
                                const temp = [].concat(more);
                                temp[v] = !temp[v];
                                setMore(temp);
                              }}
                            />
                          )}
                          {more[v] && (
                            <>
                              <p className="g-text">{i.des}</p>{' '}
                              <BsChevronUp
                                className={styles.more}
                                onClick={() => {
                                  const temp = [].concat(more);
                                  temp[v] = !temp[v];
                                  setMore(temp);
                                }}
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                    {/* <!-- Social Icon --> */}
                    {i.link && (
                      <div className="team-social-icon">
                        <a href={i.link.url} target="_blank" rel="noreferrer">
                          {/* <i className="fa fa-linkedin"></i> */}
                          <img src="./img/icons/Logomark-White.svg" alt="" />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </Element>
  );
};
export default OurTeam;
