import { BsXLg } from 'react-icons/bs';
import { useEffect } from 'react';
import styles from './styles.module.css';

export const Popup = ({ children, controller }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <div className={styles.wrap}>
      <div className={styles.background} onClick={controller}></div>
      <div className={styles.container}>
        {children}
        <BsXLg className={styles.close} onClick={controller} />
      </div>
    </div>
  );
};
