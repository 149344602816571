import styles from './styles.module.css';

const SinglePage = (props) => {
  const { id } = props.match.params;
  return (
    <div className={styles.container}>
      <div dangerouslySetInnerHTML={{ __html: handleArticle({ id }) }} />
    </div>
  );
};
const handleArticle = ({ id }) => {
  let article;
  switch (id) {
    case 'binance':
      article = binance;
      break;
    case 'maicoin':
      article = maicoin;
      break;
    case 'binanceWithdrawal':
      article = binanceWithdrawal;
      break;
    default:
      alert('頁面不存在！')// eslint-disable-line no-alert
      break;
  }
  return article;
};
const imgUrl = '/img/singlePage'
const binance = `
<h1 class="col-12">加密貨幣交易所幣安教學：註冊和身份認證</h1>
<img src="${imgUrl}/binance/img1.jpeg" alt="照片中提到了BINANCE、exchange the world，包含了皮克斯、幣安、加密貨幣、比特幣、變更局"></img>
<p>幣安是什麼？幣安Binance是世界最大的加密貨幣交易所，比今年剛在美國上市的CoinBase交易所交易量還要大十倍。加密貨幣的世界深似海，每天有各種新發展和割韭菜詐騙事件。對於一個初學者來說，先開設一個幣安交易所的帳戶（就好像要買股票先開一個玉山證券的戶頭一樣），可以當作是小白進入加密貨幣世界的第一步。
</p>


<p>當然，幣安也不是唯一解，但以初學者來說還是從幣安開始最好，作者自己就是從幣安註冊、MetaMask註冊、開始購買加密貨幣這樣一步一步走來的。而且註冊幣安有一個好處，所有流程都是用繁體中文進行，沒有國界問題。</p>

<h3>註冊幣安教學：準備好電話、身分證件、個人頭貼照片和Google Authenticator</h3>

<h4>首先先進入幣安註冊：<a href="https://lihi1.com/dFHsf" target="_blank">https://lihi1.com/dFHsf</a></h4>

<p>
    <img class="" src="${imgUrl}/binance/img2.jpeg" alt="" style="" />
</p>

<p>▲註冊的時候畫面應該會自動選+886的電話選項，輸入你的手機號碼以及設定密碼，密碼的重點是一定要有一個英文大寫和數字。</p>

<h4>接著進入基礎認證，這裡要準備的是你的姓名、出生日和住址：</h4>

<p>
    <img class="" src="${imgUrl}/binance/img3.jpeg" alt="照片中提到了基礎認證、身份信息、國籍，包含了屏幕截圖、產品設計、牌、產品、屏幕截圖" style="" />
</p>

<p>▲中間名不輸入沒關係，出生日不要亂填喔，如果小於18歲系統會不過。</p>



<p>
    <img class="" src="${imgUrl}/binance/img4.jpeg" alt="照片中提到了基礎認證、额外資訊、地址，包含了屏幕截圖、產品設計、牌、產品、屏幕截圖" style="" />
</p>

<p>&nbsp;</p>

<p>▲地址也是中文輸入就可。</p>



<p>
    <img class="" src="${imgUrl}/binance/img5.jpeg" alt="照片中提到了基礎認證、通過基本驗證、立即開始交易並選擇升級至更高限額。，包含了圖、胚胎學、配子、植入、牌"
        style="" />
</p>

<p>▲到這裡基礎驗證就完成了，但這只是完成註冊的第一步，如果做到這裡每天交易額都會受到限制很不方便。接下來我們要邁向第二步中級驗證：</p>

<h4>中級驗證：需要身份證（或護照/駕照）和Google Authenticator驗證程式</h4>

<p>
    <img class="" src="${imgUrl}/binance/img6.jpeg" alt="照片中提到了中級驗證、簽發國、Taiwan (台灣)，包含了屏幕截圖、產品設計、牌、產品、屏幕截圖" style="" />
</p>

<p>&nbsp;</p>

<p>▲首先選擇好你的證件上傳，證件照片背景要是素色的、不要切到邊、單張照片檔案不要超過5MB。</p>


<p>
    <img class="" src="${imgUrl}/binance/img7.png"
        alt="照片中提到了Enable Google Authenticator、Download App、Scan QR Code，包含了幣安2fa、谷歌身份驗證器、多因素認證、驗證、二維碼" style="" />
</p>

<p>▲上傳後系統會引導你做Google Authenticator二次驗證，首先先下載這個應用程式。</p>



<p>
    <img class="" src="${imgUrl}/binance/img8.png"
        alt="照片中提到了Enable Google Authenticator、Download App、Scan QR Code，包含了幣安谷歌驗證器、安全令牌、谷歌身份驗證器、多因素認證、驗證" style="" />
</p>

<p>▲接著會出現一個QRCode。</p>

<p>
    <img class="" src="${imgUrl}/binance/img9.png" alt="照片中提到了ll vodafone UK ?、17:04、1 * 50%，包含了數、產品、屏幕截圖、牌、字形"
        style="" />
</p>

<p>▲使用者就在Google Authenticator中掃描QRCode，掃完就會出現6位數號碼，這個以後會常需要用到，程式不要刪掉。</p>


<p>&nbsp;</p>

<p>
    <img class="" src="${imgUrl}/binance/img10.jpeg" alt="照片中提到了人臉信息認證、驗證成功、關閉，跟馬古什有關，包含了圖形、商標、牌、產品設計、字形" style="" />
</p>

<p>▲完成後系統會引導使用者下載幣安的APP，建議直接到App Store或Google Play搜尋「幣安」下載即可，下載後再掃描網站上的QRCode，掃瞄完後系統會引導你做一次人臉掃描，這個很快就可做好，這樣就大功告成了！</p>

<p>
    <img class="" src="${imgUrl}/binance/img11.jpeg" alt="照片中提到了O BINANCE E、買幣TWD、市場 交易▼，包含了軟件、幣安、加密貨幣交換、加密貨幣、比特幣"
        style="" />
</p>

<p>&nbsp;</p>

<p>▲完成後就可以看到你完成了兩樣認證。</p>

<p>
    <img class="" src="${imgUrl}/binance/img12.jpeg" alt="照片中提到了個人認證回、回影片教學、O您當前的限額，包含了屏幕截圖、商標、產品設計、計算機程序、牌" style="" />
</p>

<p><br />
    ▲到<a href="https://www.binance.com/zh-TW/my/settings/profile?ref=RRV8DHYC"
        target="_blank">個人認證頁</a>也可以確認你的認證條件以及可以執行的功能囉。</p>


<p>
    <img class="" src="${imgUrl}/binance/img13.jpeg" alt="照片中提到了資產充值、fiattitle、法幣充值，跟西格瑪努、太陽有關，包含了圖標、幣安、幣安、計算平台、電子貨幣"
        style="" />
</p>

<p><strong>下一步就是購買加密貨幣了，接著：</strong></p>

<p><strong><a href="https://www.cool3c.com/article/164566/amp" target="_blank">學習如何在幣安用信用卡和街口/LINE
            PAY購買加密貨幣</a></strong></p>
<p><a href="https://dev.404fund.org/article/binanceWithdrawal" target="_blank">幣安出金加密貨幣到FTX交易所教學：其他錢包也適用</a></p>
<p>幣安註冊：<a href="https://lihi1.com/dFHsf" target="_blank">https://lihi1.com/dFHsf</a></p>
`
const maicoin = `
<h1 class="col-12">
  MaiCoin加密貨幣註冊購買出金教學：買幣方便但最難的一關是手持證件自拍
</h1>
<img src="${imgUrl}/maicoin/img1.jpeg" alt="" />
<p>
  比特幣又創最高價，最近加密貨幣異常火熱，在台灣如果要快速購買加密貨幣、又考慮安全問題的話，可以考慮MaiCoin加密貨幣平台。MaiCoin最大的特色就是可以在7-11和萊爾富付款購買，對不喜歡銀行轉帳的朋友來說相當划算。但因為銀行轉帳的費用通常是0-15元、透過7-11或萊爾富的話就需要額外25元的手續費，在意交易成本的話這點要注意。
</p>

<p>
  在試用完MaiCoin後從註冊到購買加密貨幣的流程中，最難的關卡就是身份認證的「手持證件自拍」，這個部分需要個人拿著證件以及一張寫有僅供MaiCoin註冊和日期的白紙然後三者一起入鏡，除了需要一點恥力以外，還要將證件拍的有些清楚，在試用過程中還被退件一次，需要注意到這點才行。
</p>

<p>
  除了MaiCoin以外，也可以透過幣安購買加密貨幣，差別是可以用信用卡和LINE
  PAY等更方便的支付工具購買：
</p>

<ul>
  <li>加密貨幣交易所幣安教學：註冊和身份認證</li>
  <li>
    <a href="https://www.cool3c.com/article/164566/amp" target="_blank"
      >幣安信用卡購買虛擬貨幣教學：街口支付和LINEPAY也可以</a
    >
  </li>
</ul>

<h3>以下為MaiCoin註冊以及購買加密貨幣的圖文教學：</h3>

<p>
  <img class="lazyload" src="${imgUrl}/maicoin/img2.jpeg" alt="" style="" />
</p>

<p>
  到<a href="https://www.maicoin.com/" target="_blank">MaiCoin官網</a
  >以Email進行註冊，這裡注意要是20歲以上才能註冊。
</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img3.jpeg"
    alt="照片中提到了手機驗證、輸入您的手機號碼、+886 0933123456，包含了圖、產品設計、產品、商標、字形"
    style=""
  />
</p>

<p>接著輸入手機號碼收取驗證碼。</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img4.jpeg"
    alt="照片中提到了身分驗證、手機號碼已提交、V手機號碼，包含了圖、產品、產品設計、商標、字形"
    style=""
  />
</p>

<p>接下來填入個人資料</p>

<p>
  <img class="lazyload" src="${imgUrl}/maicoin/img5.jpeg" alt="" style="" />
</p>

<p>填入資料有一個特別的地方，要寫出發證地點和補證地點</p>

<p>
  <img class="lazyload" src="${imgUrl}/maicoin/img6.jpeg" alt="" style="" />
</p>

<p>接著上傳身分證照片正反面</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img7.jpeg"
    alt="照片中提到了了、銀行帳戶、啟用銀行轉帳購買,需綁定本人銀行帳戶,若以下顯示戶名與您實際戶名不一致請撥打客，包含了屏幕截圖、線、屏幕截圖、字形、產品"
    style=""
  />
</p>

<p>綁定銀行帳戶，這個部分做完才能做到匯款到指定帳號後立即收到加密貨幣。</p>

<p>
  <img class="lazyload" src="${imgUrl}/maicoin/img8.jpeg" alt="" style="" />
</p>

<p>銀行驗證實際測試需要2天的時間才能審核完成。</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img9.png"
    alt="照片中提到了1 PR MAX FE、註冊作用、2o 21/ 3 15，跟提高甘蔗的雞手指有關，包含了眼鏡、眼鏡、視覺感知、產品、生物學"
    style=""
  />
</p>

<p>
  接下來這是最難的步驟，要讓自己和身分證、還有一張寫有「僅限MaiCoin平台註冊使用+日期」的紙張，我拍了二次送審才過。心得是拍照的時候相機要靠證件進一點拍大一點，人臉有完整拍到就好，以證件照為主，通過機率就會比較高。老實說我覺得這個方式有點奇怪，我註冊幣安的時候也沒有這樣的KYC方式，這可能會是讓人們註冊MaiCoin最後失敗的重要關鍵。上圖是MaiCoin教學文章截圖，也可<a
    href="https://blog.maicoin.com/2021/04/08/id-verification-1/"
    target="_blank"
    >到文章看詳盡教學</a
  >。
</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img10.jpeg"
    alt="照片中提到了15:44、..l 4G、搜尋，包含了屏幕截圖、商標、字形、產品、線"
    style=""
  />
</p>

<p>
  大約過二天後應該證件和銀行都審核通過，接下來就能來買幣了，我們直接進入MaiCoin
  App操作，要練手就先買和美元綁定1:1的USDT泰達幣，這裡先點進去。
</p>

<p>&nbsp;</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img11.png"
    data-src="https://sw.cool3c.com/user/6/2021/025c8697-f346-42de-9b1f-bc49e6ffd0f9.png?fit=max&amp;w=2400&amp;q=80"
    alt="照片中提到了15:44、.ll 4G、搜尋，跟春分有關，包含了圖、線、字形、屏幕截圖、圖"
    style=""
  />
</p>

<p>接著點擊下面的「買入」按鈕。</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img12.png"
    alt="照片中提到了15:44、.ll 4G、▲搜尋，包含了인스 타 그램 보내 드린 短信 코드 를 입력 하세요、iPhone X、的iOS、移動應用、人臉編號"
    style=""
  />
</p>

<p>
  然後輸入要買多少USDT，App顯示最少購買量是8顆，但因為匯款需要15元手續費、7-11則要25元，建議一次不要買太少量，否則手續費就虧了。希望未來<a
    href="https://www.cool3c.com/article/164566"
    target="_blank"
    >MaiCoin可以支援LINE Pay和街口，幣安都支援了多方便啊</a
  >！
</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img13.png"
    alt="照片中提到了15:45、.ll 4G、搜尋，跟CP全部、米德爾塞克斯社區學院有關，包含了網頁、移動應用、安卓系統、軟件、求救"
    style=""
  />
</p>

<p>
  輸入完後就可以選擇付款選項，我這裡選擇的是銀行帳號轉帳，要注意7-11和萊爾富付款有上限二萬元的限制。<br />
  &nbsp;
</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img14.jpeg"
    alt="照片中提到了20:12、訂單詳情、捨棄，包含了pinjam uang 10 juta di koperasi、貸款、錢、現金、信用"
    style=""
  />
</p>

<p>
  接著就會出現匯款帳號，這時我們要複製帳號再到自己的銀行App匯款。說實話我覺得這段也是很麻煩，如果可以串接LINE
  PAY就可以一次做完了，每次複製轉帳帳號都會有轉帳失敗的心理壓力啊...
</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img15.jpeg"
    alt="照片中提到了20:13、令C、く，包含了屏幕截圖、屏幕截圖、線、字形、網頁"
    style=""
  />
</p>

<p>接著就用我自己的富邦銀行App進行轉帳</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img16.png"
    alt="照片中提到了20:14、訂單詳情、付款完成，包含了網頁、凱爾英雄、在線蔬菜和水果交付應用程序 - Fruzi、Web開發、移動應用"
    style=""
  />
</p>

<p>
  轉完帳不到10秒鐘MaiCoin
  App就顯示購買成功了，到這裡我們就算完成購買加密貨幣所有流程。大概整個跑完一遍就滿熟悉的，第一次還是會覺得麻煩，就學習一下囉。
</p>

<h3>透過MaiCoin App將加密貨幣出金轉到其他錢包</h3>

<p>
  加密貨幣最大的好處就是移動加密貨幣到不同錢包（可當作是一種「轉帳」行為），方便又快。例如我想要去FTX做加密貨幣放貸賺取利息，我不用跑去銀行匯款到FTX海外帳號，我只需要從MaiCoin
  App將USDT轉到FTX的錢包就可以了。
</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img17.png"
    alt="照片中提到了20:37、搜尋、〈發送到，包含了venmo 後四位數字、iPhone X、文莫、移動應用、的iOS"
    style=""
  />
</p>

<p>
  從MaiCoin
  App下方表單中選擇「錢包」，再選擇USDT、然後按發送，就會進到上圖的畫面，這裡我們輸入剛剛買的100USDT。
</p>

<p>
  <img class="lazyload" src="${imgUrl}/maicoin/img18.png" alt="" style="" />
</p>

<p>接著要新增聯絡人，聯絡人就是你要轉出的錢包位址</p>

<p>
  <img class="lazyload" src="${imgUrl}/maicoin/img19.png" alt="" style="" />
</p>

<p>
  上面名稱自訂就可，例如我要匯到FTX就命名為404FTX，下面則輸入要匯入的地址，MainCoin只接受TRC20和ERC20的位址，我推薦大家選擇ERC20的位址，因為轉傳的手續費低，只要1USDT就可，ERC20是走以太鏈，通常都要10-20USDT的手續費非常高。
</p>

<p>&nbsp;</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img20.png"
    alt="照片中提到了20:38、搜尋、〈發送 USDT，包含了紙、1972年美國總統選舉、民意調查、羅珀民意研究中心、輿論"
    style=""
  />
</p>

<p>完成後就會有上述畫面，再度確認以後勾選確認鈕後就按「確認發送」。</p>

<p>
  <img class="lazyload" src="${imgUrl}/maicoin/img21.png" alt="" style="" />
</p>

<p>這邊還要再收一次手機驗證碼，做最後確認。</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img22.png"
    alt="照片中提到了20:38、《搜尋、交易詳情，包含了紙、引文、APA風格、紙、參考書目"
    style=""
  />
</p>

<p>
  完成後就會出現上圖轉帳情形，確實被扣了1USDT做為手續費。注意這裡就算轉1000USDT，也是1USDT的手續費。
</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/maicoin/img23.jpeg"
    alt="照片中提到了21:26、个、USD Tether，包含了摩托羅拉 atrix 4g、摩托羅拉 atrix 4g、多媒體、摩托羅拉、產品"
    style=""
  />
</p>

<p>
  在FTX 錢包這邊也確認收到99USDT了，如果要即時確認的話也可以用&nbsp;<a
    href="https://tronscan.org/"
    target="_blank"
    >Tron Scan 輸入位址</a
  >來查詢確認。
</p>
`
const binanceWithdrawal = `
<h1 class="col-12">幣安出金加密貨幣到404 Fund錢包教學</h1>

<img src="${imgUrl}/binanceWithdrawal/img1.jpeg" alt="" />

<p>
  當我們<a href="https://dev.404fund.org/article/binance" target="_blank"
    >註冊加密貨幣交易所幣安</a
  >，並透過<a href="https://www.cool3c.com/article/164566" target="_blank"
    >信用卡或LINE PAY購買加密貨幣</a
  >後，也可以把加密貨幣快速移轉到其他錢包之中，今天以轉帳穩定幣USDT到FTX為例子做步驟教學：
</p>

<p>
  <img class="lazyload" src="${imgUrl}/binanceWithdrawal/img2.jpeg" alt="" />
</p>

<p>
  ▲先在幣安App裡點選「資金」然後在上方的選擇現貨，就可以看到你目前的加密貨幣資產。
</p>
<p>&nbsp;</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/binanceWithdrawal/img3.jpeg"
    alt="照片中提到了USDT、總資產、178.05215209 = $177.91，包含了屏幕截圖、計算機程序、屏幕截圖、多媒體、電腦"
  />
</p>

<p>
  ▲接著點進USDT中，可以看到下面有兩顆按鈕「提現」和「儲值」，點提現就是出金的意思。
</p>

<p>&nbsp;</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/binanceWithdrawal/img4.jpeg"
    alt="照片中提到了23:26、提現USDT、地址，包含了屏幕截圖、幣安、LIQD、元掩碼、軟件"
  />
</p>

<p>
  ▲接著點進USDT中，可以看到下面有兩顆按鈕「提現」和「儲值」，點提現就是出金的意思。
</p>

<p>&nbsp;</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/binanceWithdrawal/img5.jpeg"
    alt="照片中提到了23:25、新增地址、幣種，包含了屏幕截圖、Codashop、安卓系統、PUBG移動、PlayerUnknown的戰場"
  />
</p>

<p>▲新增地址中：</p>

<p>幣種選擇「USDT」。</p>

<p>地址輸入我們想要的地址，例如：TDUEVi2Kg3HnPDbnaxnHnxKtMimtJgGvsV&nbsp;</p>

<p>
  主網類型：上述地址為
  Tron（TRC20），所以選擇這個。注意！幣種、地址、主網都要一致才行。
</p>
<p>
  地址來源：這個可以隨意選擇輸入，我就選擇錢包&gt;其他 （選擇交易所&gt;其他
  也可以）。
</p>

<p>錢包標籤：取一個自己習慣的名字就可，例如這裡取404Fund。</p>

<p>接著勾選「加入白名單」，然後按「保存」就可以了。</p>

<p>&nbsp;</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/binanceWithdrawal/img6.jpeg"
    alt="照片中提到了23:26、提現USDT、地址，包含了屏幕截圖、幣安、LIQD、元掩碼、軟件"
  />
</p>

<p>
  ▲再回到提現USDT畫面，選擇我們剛剛新增的地址「404Fund」、主網類型可以讓幣安自動選擇也可以直接選擇TRC20，提現金額就自己填入。注意！如果你要提現大筆USDT到一個位址上，建議先小筆提現，確認成功後再大筆提現。
</p>

<p>&nbsp;</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/binanceWithdrawal/img7.jpeg"
    alt="照片中提到了23:26、提現USDT、地址，包含了屏幕截圖、幣安智能鏈、多媒體、幣安、文本"
  />
</p>

<p>▲選擇主網類型為TRC20時，就會告知您需要手續費1USDT。</p>

<p>&nbsp;</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/binanceWithdrawal/img8.jpeg"
    alt="照片中提到了23:35、提現USDT、提現USDT，包含了屏幕截圖、繫繩、庫幣交易所、幣安智能鏈、幣安"
  />
</p>
<p>▲幣安會再讓你確認一次，沒問題後按確認。</p>

<p>&nbsp;</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/binanceWithdrawal/img9.jpeg"
    alt="照片中提到了23:25、安全驗證、將發送驗證碼到您的93 902，包含了屏幕截圖、以太坊、加密貨幣錢包、幣安、錢"
  />
</p>

<p>▲然後要輸入兩個驗證碼，一個是簡訊驗證、一個是Google驗證碼。</p>

<p>&nbsp;</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/binanceWithdrawal/img10.jpeg"
    alt="照片中提到了23:35、提現申請已提交、提現申請已提交，包含了幣安驗證、幣安、加密貨幣、以太坊、錢"
  />
</p>

<p>
  ▲完成後就會出現提現申請成功畫面，這時候就等著，通常2-30分鐘後另一邊錢包就會收到。
</p>

<p>&nbsp;</p>

<p>
  <img
    class="lazyload"
    src="${imgUrl}/binanceWithdrawal/img11.jpeg"
    alt="照片中提到了00:16、提現詳情、數量，包含了屏幕截圖、多媒體、字形、屏幕截圖、圖形"
  />
</p>

<p>▲查看歷史就可看到提現詳情。</p>
<p>
  幣安提現的過程第一次需要花比較多時間，之後就會方便許多，按照上面的畫面一步一步做應該都可以順利完成。
</p>
`
export default SinglePage;
