import { Component } from 'react';

export default class Token extends Component {
  render() {
    return (
      <section
        className="token section-padding-100-0 clearfix"
        id="about_token"
      >
        <div className="container">
          <div className="section-heading text-center">
            {/* <!-- Dream Dots --> */}
            <div
              className="dream-dots justify-content-center fadeInUp"
              data-wow-delay="0.2s"
            >
              <span>About</span>
            </div>
            <h2 className="fadeInUp" data-wow-delay="0.3s">
              投資策略
            </h2>
            {/* <p className="fadeInUp" data-wow-delay="0.4s">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis
              accumsan nisi Ut ut felis congue nisl hendrerit commodo.
            </p> */}
          </div>

          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              {/* <!-- Content --> */}
              <div
                className="service_single_content text-center mb-100 fadeInUp"
                data-wow-delay="0.2s"
              >
                {/* <!-- Icon --> */}
                <div className="service_icon">
                  <img src="img/services/token1.svg" alt="" />
                </div>
                <h6>自動化投放</h6>
                <p>
                  原創機器學習模型，在市場中不斷尋找最佳的套利機會並且24小時自動投放
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              {/* <!-- Content --> */}
              <div
                className="service_single_content text-center mb-100 fadeInUp"
                data-wow-delay="0.3s"
              >
                {/* <!-- Icon --> */}
                <div className="service_icon">
                  <img src="img/services/token2.svg" alt="" />
                </div>
                <h6>相對安全</h6>
                <p>
                  同時做多和做空對沖幣價波動，在牛熊市都可以有穩定的高額報酬
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              {/* <!-- Content --> */}
              <div
                className="service_single_content text-center mb-100 fadeInUp"
                data-wow-delay="0.4s"
              >
                {/* <!-- Icon --> */}
                <div className="service_icon">
                  <img src="img/services/token3.svg" alt="" />
                </div>
                <h6>獲利更佳</h6>
                <p>一個大的資金池可以抓住許多小資金池無法抓住的獲利機會</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
