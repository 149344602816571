/*eslint-disable*/
import { useState, useEffect } from 'react';
import axios from 'axios';
import BN from 'bignumber.js';
import {
  BsFillInfoCircleFill,
  BsCaretDownFill,
  BsCaretUpFill,
} from 'react-icons/bs';
import styles from './styles.module.css';
import { ProgressBar } from '../ProgressBar';
import { Popup } from '../Popup';

const Dashboard = (props) => {
  const { id } = props.match.params;
  console.log(props.match.params,123)
  const [asset, setAsset] = useState('');
  const [fundingRateApy, setFundingRateApy] = useState('');
  const [history, setHistory] = useState('');
  const [totalInvestment, setTotalInvestment] = useState('');
  const [apy, setApy] = useState('');
  const [loading, setLoading] = useState(true);
  const [managementFeePopup, setManagementFeePopup] = useState(false);
  const [feesDetail, setFeesDetail] = useState(false);
  const stepDistance = [10000, 35000, 100000];
  const managementFee = [0.35, 0.3, 0.25];
  const userLevel = asset < 35000 ? 0 : asset < 100000 ? 1 : 2;
  const handlemanagementFeePopup = () => {
    setManagementFeePopup(!managementFeePopup);
  };
  const handleFeesDetail = () => {
    setFeesDetail(!feesDetail);
  };
  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get(`/api/report/${id}`);
      setAsset(data.asset);
      setFundingRateApy(data.fundingRateApy);
      setHistory(data.history);
      setTotalInvestment(data.totalInvestment);
      setApy(data.apy);
      setLoading(false);
    };
    getData();
  }, [id]);
  console.log({
    asset,
    fundingRateApy,
    history,
    totalInvestment,
    apy,
    loading,
  });

  return (
    !loading && (
      <div className={styles.section}>
        <div className={styles.container}>
          <div className={styles.topInfo}>
            <p className={styles.fundingRate}>
              Estimated Next Funding Rate:{' '}
              <span
                className={
                  new BN(fundingRateApy).times(100).toNumber().toFixed(2) >
                  new BN(apy)
                    .times(100)
                    .times(new BN(1).minus(managementFee[userLevel]))
                    .toNumber()
                    .toFixed(2)
                    ? styles.greatRate
                    : null
                }
              >
                {new BN(fundingRateApy).times(100).toNumber().toFixed(2)}%
              </span>{' '}
              APY
            </p>
            <div className={styles.asset}>
              {new BN(asset).toNumber().toFixed(2)} USDT
            </div>
            <ProgressBar
              percent={new BN(asset).div(stepDistance[userLevel + 1])}
              level={userLevel}
            />
            <div className={styles.progressBarText}>
              <p>
                Deposit{' '}
                {new BN(stepDistance[userLevel + 1])
                  .minus(asset)
                  .toNumber()
                  .toFixed(2)}{' '}
                to become LP{userLevel + 1} now{' '}
                <BsFillInfoCircleFill onClick={handlemanagementFeePopup} />
              </p>
            </div>
            {/* <div className={styles.total}>
              <p>
                Total Earned:{" "}
                {new BN(asset).minus(totalInvestment).toNumber().toFixed(2)}
              </p>
            </div> */}
            <div className={styles.profit}>
              <div className={styles.apy}>
                <p>APY</p>
                <p>
                  {new BN(apy)
                    .times(100)
                    // .times(new BN(1).minus(managementFee[userLevel]))
                    .toNumber()
                    .toFixed(2)}
                  %
                </p>
              </div>
              <div className={styles.earn}>
                <p>Earned</p>
                <p>
                  {new BN(asset)
                    .minus(totalInvestment)
                    // .times(new BN(1).minus(managementFee[userLevel]))
                    .toNumber()
                    .toFixed(2)}
                  USDT
                </p>
              </div>
            </div>
          </div>
          <div className={styles.downInfo}>
            <div className={styles.refer}>
              <h5>Refer Earned: coming soon</h5>
            </div>
            {/* <div className={styles.feesWrap} onClick={handleFeesDetail}>
              <div className={styles.fees}>
                <h5>
                  Fees:{" "}
                  {new BN(asset)
                    .minus(totalInvestment)
                    .times(managementFee[userLevel])
                    .toNumber()
                    .toFixed(2)}
                  USDT
                </h5>
                {feesDetail ? <BsCaretUpFill /> : <BsCaretDownFill />}
              </div>
              {feesDetail && (
                <>
                  <p>
                    Management Fee({managementFee[userLevel] * 100}%):{" "}
                    {new BN(asset)
                      .minus(totalInvestment)
                      .times(managementFee[userLevel])
                      .toNumber()
                      .toFixed(2)}
                    USDT
                  </p>
                  <p>Front-End Load: 0</p>
                  <p>Custodian Fee: 0</p>
                </>
              )}
            </div> */}
            <h2>History</h2>
            <div className={styles.historys}>
              {history &&
                history.map((i) => (
                  <div className={styles.history}>
                    <div className={styles.historyDetail}>
                      <h4>{i[0]}</h4>
                      <p>
                        {new Date(i[2]).toLocaleString('en-us', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </p>
                    </div>
                    <h3>{i[1]} USDT</h3>
                  </div>
                ))}
            </div>
          </div>
          {managementFeePopup && (
            <Popup controller={handlemanagementFeePopup}>
              <div className={styles.managementFee}>
                <h3>Management Fee</h3>
                <div className={styles.total}>
                  <p>
                    Total Earned:{' '}
                    {new BN(asset).minus(totalInvestment).toNumber().toFixed(2)}
                  </p>
                  <p>
                    Total APY: {new BN(apy).times(100).toNumber().toFixed(2)}%
                  </p>
                </div>
                <div className={styles.feesWrap} onClick={handleFeesDetail}>
                  <div className={styles.fees}>
                    <h5>
                      Fees:{' '}
                      {new BN(asset)
                        .minus(totalInvestment)
                        .times(managementFee[userLevel])
                        .toNumber()
                        .toFixed(2)}
                      USDT
                    </h5>
                    {feesDetail ? <BsCaretUpFill /> : <BsCaretDownFill />}
                  </div>
                  {feesDetail && (
                    <>
                      <p>
                        Management Fee({managementFee[userLevel] * 100}%):{' '}
                        {new BN(asset)
                          .minus(totalInvestment)
                          .times(managementFee[userLevel])
                          .toNumber()
                          .toFixed(2)}
                        USDT
                      </p>
                      <p>Front-End Load: 0</p>
                      <p>Custodian Fee: 0</p>
                    </>
                  )}
                </div>
                <table>
                  <tr>
                    <td></td>
                    <td>asset</td>
                    <td>Management Fee</td>
                    {/* <td>Management Fee percentage</td> */}
                  </tr>
                  {stepDistance.map((value, index) => (
                    <tr
                      className={
                        index === userLevel
                          ? styles.currentLevel
                          : styles.otherLevel
                      }
                    >
                      <td>{`LP${index}:`}</td>
                      <td>{value}</td>
                      <td>
                        {new BN(asset)
                          .minus(totalInvestment)
                          .times(managementFee[index])
                          .toNumber()
                          .toFixed(2)}{' '}
                        USDT ({managementFee[index] * 100}%)
                      </td>
                      {/* <td>{managementFee[index] * 100}%</td> */}
                    </tr>
                  ))}
                </table>
              </div>
            </Popup>
          )}
        </div>
      </div>
    )
  );
};
export default Dashboard;
