// import { useState, useEffect } from 'react';
import styles from './styles.module.css';

export const ProgressBar = ({ percent, level }) => (
    <div className={styles.container}>
      <span>LP{level}</span>
      <div className={styles.progressDiv}>
        <div
          style={{ width: `${percent * 100}%` }}
          className={styles.progress}
        />
      </div>
      <span>LP{level + 1}</span>
    </div>
  );
