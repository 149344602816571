import { Component } from 'react';

export default class Footer extends Component {
  render() {
    return (
      <footer
        className="footer-area bg-img"
        style={{ backgroundImage: 'url(img/core-img/pattern.png)' }}
      >
        {/* <!-- ##### Contact Area Start ##### --> */}
        <div className="contact_us_area section-padding-100-0" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-heading text-center">
                  {/* <!-- Dream Dots --> */}
                  <div
                    className="dream-dots justify-content-center fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    <img src="img/svg/section-icon-5.svg" alt="" />
                  </div>
                  <h2 className="fadeInUp" data-wow-delay="0.3s">
                    聯絡我們
                  </h2>
                  <p className="fadeInUp" data-wow-delay="0.4s">
                    加入社群了解更多
                  </p>
                </div>
              </div>
            </div>

            {/* <!-- Contact Form --> */}
            {/* <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8">
                <div className="contact_form">
                  <form
                    action="#"
                    method="post"
                    id="main_contact_form"
                    noValidate
                  >
                    <div className="row">
                      <div className="col-12">
                        <div id="success_fail_info"></div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="group fadeInUp" data-wow-delay="0.2s">
                          <input type="text" name="name" id="name" required />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label>Name</label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="group fadeInUp" data-wow-delay="0.3s">
                          <input type="text" name="email" id="email" required />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label>Email</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="group fadeInUp" data-wow-delay="0.4s">
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label>Subject</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="group fadeInUp" data-wow-delay="0.5s">
                          <textarea name="message" id="message" required />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label>Message</label>
                        </div>
                      </div>
                      <div
                        className="col-12 text-center fadeInUp"
                        data-wow-delay="0.6s"
                      >
                        <button type="submit" className="btn dream-btn">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
            <div className="row justify-content-center">
              <button
                className="btn dream-btn"
                onClick={() => window.open('https://t.me/foffund')}
              >
                Telegram 社群
              </button>
            </div>
          </div>
        </div>
        {/* <!-- ##### Contact Area End ##### --> */}

        <div className="footer-content-area ">
          <div className="container">
            <div className="row ">
              <div className="col-sm-12 text-center">
                <p className="rights">All Rights Reseved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
