import { useState, useEffect } from 'react';
import axios from 'axios';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import BN from 'bignumber.js';
import { Link } from 'react-scroll';
import { Popup } from '../Popup';
import styles from './styles.module.css';

// axios.defaults.baseURL = 'http://localhost:3000';

const Welcome = () => {
  const [pool, setPool] = useState(null);
  const [apy, setApy] = useState(null);
  const [sT, setST] = useState(null);
  const [capitalPool, setCapitalPool] = useState(null);

  const [managementFeePopup, setManagementFeePopup] = useState(false);
  const handlemanagementFeePopup = () => {
    setManagementFeePopup(!managementFeePopup);
  };
  useEffect(() => {
    const initPool = async () => {
      const data = await axios.get('/api/pool');
      const { t } = data.data;
      const dataPool = data.data.pool;
      const dataApy = data.data.apy;
      const current = new Date().getTime();
      setPool(
        dataPool *
        (1 +
          ((dataApy * 100) / 100 / 365 / 24 / 60 / 60) *
          ((current - t) / 1000)),
      );
      setApy(dataApy * 100);
      setST(t);
      setCapitalPool(dataPool);
    };
    initPool();
  }, []);

  useEffect(() => {
    const handlePool = () => {
      const current = new Date().getTime();
      setPool(
        capitalPool *
        (1 + (apy / 100 / 365 / 24 / 60 / 60) * ((current - sT) / 1000)),
      );
    };
    if (!capitalPool) return null;
    const interval = setInterval(() => {
      handlePool();
    }, 1000);
    return () => clearInterval(interval);
  }, [apy, capitalPool, sT]);
  const currentFee = () => {
    if (apy < 6) return 0;
    if (apy < 7) return 0.75;
    if (apy < 8) return 1.5;
    if (apy < 9) return 2.25;
    return new BN(apy).times(0.3).toNumber().toFixed(2);
  };
  return (
    <>
      <section
        className="welcome_area clearfix dzsparallaxer auto-init none fullwidth"
        data-options='{direction: "normal"}'
        id="home"
      >
        <div
          className="divimage dzsparallaxer--target"
          style={{
            width: '101%',
            height: '130%',
            backgroundImage: 'url(img/bg-img/bg-5.png)',
          }}
        ></div>

        {/* <!-- Hero Content --> */}
        <div className="hero-content transparent">
          {/* <!-- blip --> */}
          <div className="dream-blip blip1"></div>
          <div className="dream-blip blip2"></div>
          <div className="dream-blip blip3"></div>
          <div className="dream-blip blip4"></div>

          <div className="container h-100">
            <div className="row h-100 align-items-center">
              {/* <!-- Welcome Content --> */}
              <div className="col-12 col-lg-6 col-md-12">
                <div className="welcome-content">
                  {/* <div className="promo-section">
                    <h3 className="special-head dark">
                      Creative ICO and Crypto tarding Platform
                    </h3>
                  </div> */}
                  <h1 className="fadeInUp" data-wow-delay="0.2s">
                    去中心化金融市場中，靈活穩定的套利策略
                  </h1>
                  <p className="w-text fadeInUp" data-wow-delay="0.3s">
                    {/* 昨日年化31% */}
                    資金池大小：{pool} USDT <br />
                    當前年化報酬率(APY)：{apy}% <br />
                    手續費：獲利6%以下完全免費，詳見更多
                    <BsFillInfoCircleFill onClick={handlemanagementFeePopup} />
                  </p>
                  <div
                    className="dream-btn-group fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <a
                      href="https://t.me/foffund"
                      className="btn dream-btn mr-3 mb-3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      聯絡我們
                    </a>
                    <Link to="team" smooth={true}>
                      <a href="" className="btn dream-btn mb-3">
                        關於我們
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {managementFeePopup && (
            <Popup controller={handlemanagementFeePopup}>
              <div className={styles.managementFee}>
                <h3>Management Fee</h3>
                <p>LP1 current fee: {currentFee()}%</p>
                <table>
                  <tr>
                    <td></td>
                    <td>{'<6%'}</td>
                    <td>{'7%'}</td>
                    <td>{'8%'}</td>
                    <td>{'9%'}</td>
                    <td>{'>=10% => N%'}</td>
                  </tr>
                  <tr>
                    <td>{'>10k'}</td>
                    <td>0</td>
                    <td>{'0.75%'}</td>
                    <td>{'1.5%'}</td>
                    <td>{'2.25%'}</td>
                    <td>{'N * 0.3'}</td>
                  </tr>
                  <tr>
                    <td>{'>35k'}</td>
                    <td>0</td>
                    <td>{'0.75%'}</td>
                    <td>{'1.5%'}</td>
                    <td>{'2.25%'}</td>
                    <td>{'N * 0.25'}</td>
                  </tr>
                  <tr>
                    <td>{'>250k'}</td>
                    <td>0</td>
                    <td>Deal</td>
                    <td>Deal</td>
                    <td>Deal</td>
                    <td>Deal</td>
                  </tr>
                </table>
                <p>如上表所示，當年化報酬率低於6%以下的時候，404 Fund 不收管理費。</p>
                <p>當您投入的USDT年化報酬率到7%的時候，404 Fund 則收本金 0.75% 的管理費，您獲得的實際收益為6.25%；年化報酬率到8%的時候，404 Fund 則收本金 1.5% 的管理費，您獲得的實際收益為6.5%；年化報酬率到9%的時候，404 Fund 則收本金 2.25% 的管理費，您獲得的實際收益為6.75%；當年化報酬率超過10%的時候，不論多少我們都收取您收益30%的管理費，以收益10%計算即為3%管理費，您的實際收益為7%、以此類推。</p>

                <p>另外當超過10%的時候，如果您投入的現有資金超過35K USDT，您的管理費會下降至收益的25%，以收益10%計算即為2.5%管理費，您的實際收益為7%、以此類推。</p>

                <p>如果您的投入USDT超過250K，請和您的推薦人聯絡，我們會客製化您的管理費用。</p>

                <p>詳細收益對應的管理費，請點擊<a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRdTKtW0oDmdNu00UI2bZeXoBiIqfGMVxymvelVZtehVqAymPiZFIMLiwkGT4dqRuX0XSzzcGjWqdhc/pubhtml" target="_blank" rel="noreferrer">連結</a>。</p>

              </div>
            </Popup>
          )}
        </div>
      </section>
    </>
  );
};
export default Welcome;
