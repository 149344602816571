import React from 'react';
// import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
import Header from './component/Header';
import Welcome from './component/Welcome';
import Token from './component/Token';
// import AboutUs from './component/AboutUs';
// import AboutUs2 from './component/AboutUs2';
// import BubRight from './component/BubRight';
// import Services from './component/Services';
// import BubLeft from './component/BubLeft';
// import SpreadMap from './component/SpreadMap';
// import TokenDistribution from './component/TokenDistribution';
// import Roadmap from './component/Roadmap';
// import TokenSale from './component/TokenSale';
import Faq from './component/Faq';
import OurTeam from './component/OurTeam';
// import OurBlog from './component/OurBlog';
import Footer from './component/Footer';
import Dashboard from './component/Dashboard';
import DashboardChou from './component/DashboardChou';
import SinglePage from './component/SinglePage';

function App() {
  return (
    <Router>
      {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/article/:id" component={SinglePage}></Route>
        <Route path="/dashboard2/:id" component={DashboardChou}>
          {/* <DashboardChou /> */}
        </Route>
        <Route path="/dashboard/:id" component={Dashboard}>
          {/* <Dashboard /> */}
        </Route>
      </Switch>
    </Router>
  );
  // return (
  //   <>
  //     <div id="preloader">
  //       <div className="preload-content">
  //         <div id="dream-load"></div>
  //       </div>
  //     </div>
  //     <Header />
  //     <Welcome />
  //     <div className="clearfix" />
  //     <Token />
  //     {/* <AboutUs /> */}
  //     {/* <AboutUs2 /> */}
  //     {/* <BubRight /> */}
  //     {/* <Services /> */}
  //     {/* <BubLeft /> */}
  //     {/* <div className="clearfix" /> */}
  //     {/* <SpreadMap /> */}
  //     {/* <TokenDistribution /> */}
  //     {/* <Roadmap /> */}
  //     {/* <TokenSale /> */}
  //     <Faq />
  //     <OurTeam />
  //     {/* <OurBlog /> */}
  //     <Footer />
  //     {/* <App /> */}
  //   </>
  // );
}
const investor = [
  {
    name: '吳顯二',
    title: 'Founder at Cool3c',
    des: '我不是加密貨幣的堅定持有者，但在研究三個月後加密貨幣交易所中的價差和資金費率行為後，確認其中存在套利空間。但自己手動交易實在太過辛苦，而且也無法時刻尋找最佳的套利機會，一些交易所的機器人機制也限定在一個幣種中，在尋覓不同解法過程中遇到剛在研發中的Firebee（404Fund前身）就小試金額加入，現在已經投入164天已經獲得14%報酬，換算年化報酬超過三成，在加密貨幣交易還在火熱發展階段中，推薦您現在開始參與，且從相對安全的404Fund開始投入。',
    pic: 'https://lh3.googleusercontent.com/S8BdsMMDQ2mGFdTM71_Ncvc6Id2psj0OOShe_xd61Eueg2WhQpD34J9bUa3gMk1wXaqNIWfByGqXG-Mr2Xojg8A9owRdfz9NsEH5Tw=w600',
    link: {
      url: 'https://opensea.io/atticuswu',
      type: 'opensea',
    },
  },
  {
    name: 'Emily Chung',
    title: 'Ex-Doordash',
    des: '我任職的 Doordash 雖然是科技公司，但是在法幣的金融體系裡面很多限制，接觸到加密貨幣後發現原來還有另外一種維度。投資的部分因為沒有開市關市，加上手動交易的繁瑣， 我一直在尋找適合投資記得機會，也嘗試了市面上的一些 trading bot 但沒有很直覺，參數也要自己設定。 剛好朋友推介我404Fund，才一個月我已經有5%的回報。預計還會投入更多資金。因為套利相對安全獲利穩定。我覺得使用下來心裡比較踏實。',
    pic: 'https://lh3.googleusercontent.com/Daq2rJZ7VfvI7cF9Ru4P4VaH0h7E_SWMZcZTFKgT-0-grzhZMAhtILDhWMHuXAFCYFg_DEAPuDof35PQQ0VGeLYDSu6kNNNDYd9djQ=w600',
  },
  {
    name: 'David Lin',
    title: 'VP of Engineering at Findora',
    des: 'Arbitrage always seems like easy money in theory, but in practice there’s many pitfalls that could curb your returns or even result in losses. 404Fund’s unique execution has addressed these issues. My initial investment has returned 49% APY to date. I see this as a lower risk investment that can produce relatively stable returns.',
  },
];
const team = [
  {
    name: 'Fire Cheng',
    title: 'Founder',
    des: '前台灣高流量大型社群內容網站技術長、亞洲 Saas 開店大型電商平台，多次從 0 開始建立技術團隊，四年前開始研究全球不同加密貨幣交易所間套利交易，橫跨社群、媒體、電商、金融，於 2021 更專於極低風險的高頻與對沖交易，從大漲大跌 Crypto 中，獲取低風險及穩定的利潤。',
    pic: 'https://d321avuaz9o3qd.cloudfront.net/sst-design/ec166288-b1a1-4de8-8f2a-e70dfbaa2d83.jpg',
    // link: {
    //   url: 'https://opensea.io/atticuswu',
    //   type: 'opensea',
    // },
  },
  {
    name: 'YILE CHANG',
    title: 'Developer',
    des: '傳統金融&虛擬貨幣衍伸品交易平台工程師，橫跨金融界和網路圈，目前醉心於各種Defi服務及套利項目。',
    pic: 'https://lh3.googleusercontent.com/9c7V8w5p7WR78r78vtwjOxHGLm2b9qIjHUTps8BdHRpOrmRRS2_bfDNFuHRM_mNfGuYa4OwUfR8uFiK4D4WwYeoN0DM3ggu5Ks1U2A=w600',
    // link: {
    //   url: 'https://opensea.io/0x9f76441c9eb9006141ab87f1d38969f884449ff1',
    //   type: 'opensea',
    // },
  },
  {
    name: 'Eugene',
    title: 'COO',
    // des: 'xxx',
    pic: 'https://lh3.googleusercontent.com/r146XyV-OrOYerM0vaywPge2K-SN1cEUUJqza1yxJEJZKswLZo_17aFQHvl1CZuDrpJZLtVPRigpsBlUNR5CdLyuco5-J7hYnwrnDQ=w600',
  },
];

function Home() {
  return (
    <>
      <div id="preloader">
        <div className="preload-content">
          <div id="dream-load"></div>
        </div>
      </div>
      <Header />
      <Welcome />
      <div className="clearfix" />
      <Token />
      {/* <AboutUs /> */}
      {/* <AboutUs2 /> */}
      {/* <BubRight /> */}
      {/* <Services /> */}
      {/* <BubLeft /> */}
      {/* <div className="clearfix" /> */}
      {/* <SpreadMap /> */}
      {/* <TokenDistribution /> */}
      {/* <Roadmap /> */}
      {/* <TokenSale /> */}
      <Faq />
      <OurTeam
        data={investor}
        title="早期投資人"
        subtitle="世上韭菜那麼多 我會不會是下一個韭菜？"
        name="funder"
      />
      <OurTeam
        data={team}
        subtitle="團隊介紹"
        des="404Fund是由三位橫跨台灣和矽谷的研發工程師和產品經理所組合，目標是透過程式交易的方式，尋找全球不同交易所間加密貨幣的套利機會，目前第一款產品40401已經開發完成：透過機器人尋找「「現貨與期貨價差+交易合約資金費率-手續費>0」」，可得年化報酬率30%以上，比起年化報酬率而言，404Fund更重視本金的安全，在加密貨幣金融圈中各種鑽石、黃金和砂礫的海洋上，只尋找套利交易的機會，而不重壓各種絢爛的虛擬未來。"
        name="team"
      />
      {/* <OurBlog /> */}
      <Footer />
      {/* <App /> */}
    </>
  );
}

// function Dashboard() {
//   return (
//     <div>
//       <h2>Dashboard</h2>
//     </div>
//   );
// }

export default App;
